"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAssetBackground = exports.sanitizeColor = exports.convertColorObjToColor = exports.convertColorToColorObj = exports.getTintColorValue = exports.getSize = exports.GetColor = exports.getCustomCssSnippetsForBlocks = exports.getSourceBlockStyleSnippet = exports.getAcceptBlockStyleSnippet = exports.getMiscSnippets = exports.getQuoteBlockSnippet = exports.getSplashBlockSnippet = exports.getTintBlendModeValue = exports.getApplyTextSnippet = exports.getBlockStyle = exports.getBlockId = exports.allSnippetGetters = void 0;
var colord_1 = require("colord");
var blockConfigUtil_1 = require("./Config/blockConfigUtil");
var LessValidator_1 = require("./LessValidator/LessValidator");
var commonTypes_1 = require("./Config/BlockConfig/commonTypes");
var BlockStyleTypes_1 = require("./BlockStyleTypes");
var ColorUtils_1 = require("@CommonFrontendBackend/StyleServiceV2/ColorUtils");
exports.allSnippetGetters = [
    getMiscSnippets,
    getAcceptBlockStyleSnippet,
    getQuoteBlockSnippet,
    getSourceBlockStyleSnippet,
    getApplyTextSnippet,
    getSplashBlockSnippet,
];
function getBackgroundColor(block, getColor) {
    var _a;
    return getColor((_a = block.style) === null || _a === void 0 ? void 0 : _a.background) || "#FFFFFF";
}
function getStyleDefaultAlignment(block) {
    var style = block.style;
    var defaultAlignment = (style === null || style === void 0 ? void 0 : style.blockType) === commonTypes_1.BlockType.Text ? "left" : "center";
    return { style: style, defaultAlignment: defaultAlignment };
}
function getH1Alignment(block) {
    var _a, _b, _c;
    var _d = getStyleDefaultAlignment(block), style = _d.style, defaultAlignment = _d.defaultAlignment;
    return (_c = (_b = (_a = style === null || style === void 0 ? void 0 : style.h1) === null || _a === void 0 ? void 0 : _a.alignment) !== null && _b !== void 0 ? _b : style === null || style === void 0 ? void 0 : style.alignment) !== null && _c !== void 0 ? _c : defaultAlignment;
}
function getH2Alignment(block) {
    var _a, _b, _c;
    var _d = getStyleDefaultAlignment(block), style = _d.style, defaultAlignment = _d.defaultAlignment;
    return (_c = (_b = (_a = style === null || style === void 0 ? void 0 : style.h2) === null || _a === void 0 ? void 0 : _a.alignment) !== null && _b !== void 0 ? _b : style === null || style === void 0 ? void 0 : style.alignment) !== null && _c !== void 0 ? _c : defaultAlignment;
}
function getPAlignment(block) {
    var _a, _b, _c;
    var _d = getStyleDefaultAlignment(block), style = _d.style, defaultAlignment = _d.defaultAlignment;
    return (_c = (_b = (_a = style === null || style === void 0 ? void 0 : style.p) === null || _a === void 0 ? void 0 : _a.alignment) !== null && _b !== void 0 ? _b : style === null || style === void 0 ? void 0 : style.alignment) !== null && _c !== void 0 ? _c : defaultAlignment;
}
function getBlockId(block) {
    var _a;
    return (_a = block._id) !== null && _a !== void 0 ? _a : block.$id;
}
exports.getBlockId = getBlockId;
function getBlockStyle(block, colors) {
    var e_1, _a;
    var s = "";
    var cc = (0, exports.GetColor)(colors);
    try {
        for (var allSnippetGetters_1 = __values(exports.allSnippetGetters), allSnippetGetters_1_1 = allSnippetGetters_1.next(); !allSnippetGetters_1_1.done; allSnippetGetters_1_1 = allSnippetGetters_1.next()) {
            var getter = allSnippetGetters_1_1.value;
            s += getter(block, cc, colors[0]);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (allSnippetGetters_1_1 && !allSnippetGetters_1_1.done && (_a = allSnippetGetters_1.return)) _a.call(allSnippetGetters_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return s;
}
exports.getBlockStyle = getBlockStyle;
function getApplyTextSnippet(block, getColor) {
    var style = block.style;
    if (!style || block.type === commonTypes_1.BlockType.Source) {
        return "";
    }
    var h1Color = getColor(style.h1.color);
    return ".apply-text(~\"".concat(getBlockId(block), "\",\n    ").concat(getBackgroundColor(block, getColor), ",\n    ").concat(h1Color, ",\n    ").concat((0, exports.getSize)(style.h1.size), ",\n    ").concat(getH1Alignment(block), ",\n    ").concat(getColor(style.h2.color), ",\n    ").concat((0, exports.getSize)(style.h2.size), ",\n    ").concat(getH2Alignment(block), ",\n    ").concat(getColor(style.p.color), ",\n    ").concat((0, exports.getSize)(style.p.size), ",\n    ").concat(getPAlignment(block), ",\n    ").concat(getColor(style.blockQuote && style.blockQuote.color ? style.blockQuote.color : style.p.color), ",\n    ").concat(getColor(style.blockQuote && style.blockQuote.barColor ? style.blockQuote.barColor : style.p.color), ",\n    ").concat((0, exports.getSize)(style.blockQuote && style.blockQuote.size ? style.blockQuote.size : style.p.size), ",\n    ").concat(style.blockQuote ? style.blockQuote.displayQuoteIcon : true, ",\n    ").concat((0, blockConfigUtil_1.getBlockWidthValue)(style.blockWidth), ",\n    ").concat((0, blockConfigUtil_1.getBlockSpacingValue)(style.blockSpacing), "\n    );");
}
exports.getApplyTextSnippet = getApplyTextSnippet;
var getTintBlendModeValue = function (tintBlendMode) {
    if (tintBlendMode === "blend") {
        return "multiply";
    }
    else {
        return "normal";
    }
};
exports.getTintBlendModeValue = getTintBlendModeValue;
function getSplashBlockSnippet(block, getColor) {
    var style = block.style;
    if (!style || !(block.type === commonTypes_1.BlockType.Splash && (0, BlockStyleTypes_1.isSplashStyle)(style))) {
        return "";
    }
    return ".apply-splash-block(~\"".concat(getBlockId(block), "\",\n    ").concat(getBackgroundColor(block, getColor), ",\n    ").concat((0, exports.getTintBlendModeValue)(style.tintBlendMode), ",\n    ").concat(style.backgroundCard != null && style.backgroundCard.on ? getColor(style.backgroundCard.color) : "transparent", ",\n    ").concat((0, blockConfigUtil_1.getSplashBlockMinHeightValue)(style.blockSpacing), "\n    );");
}
exports.getSplashBlockSnippet = getSplashBlockSnippet;
function getQuoteBlockSnippet(block, getColor) {
    var style = block.style;
    if (!style || !(block.type === commonTypes_1.BlockType.Quote && (0, BlockStyleTypes_1.isQuoteStyle)(style))) {
        return "";
    }
    var backgroundColor = getBackgroundColor(block, getColor);
    return ".apply-quote-block(~\"".concat(getBlockId(block), "\",\n    ").concat(style.quoteBackgroundOn ? "#fff" : "transparent", ",\n    ").concat(style.headerSection != null && style.headerSection.on ? getColor(style.headerSection.color) : "transparent", ",\n    ").concat(style.quoteBackgroundOn ? "#fff" : backgroundColor, ",\n    ").concat(style.headerSection != null && style.headerSection.on ? getColor(style.headerSection.color) : backgroundColor, ",\n    ").concat(style.quoteBackgroundOn ? "whitesmoke" : "transparent", ",\n    ").concat(getColor(style.uiHighlightColor), ",\n    ").concat(Boolean(style.quoteBackgroundOn), ",\n    ").concat(backgroundColor, "\n    );");
}
exports.getQuoteBlockSnippet = getQuoteBlockSnippet;
function getMiscSnippets(block, getColor, defaultColor) {
    var style = block.style;
    if (!style) {
        return "";
    }
    var snippet = "";
    var blockId = getBlockId(block);
    if (!(0, BlockStyleTypes_1.isSourceStyle)(style)) {
        if (!(style.h1 && style.h2 && style.p)) {
            throw new Error("Missing text styling when getting non-Source block style");
        }
        if (style.link && style.link.color && style.link.underline) {
            snippet += ".apply-link(~\"".concat(blockId, "\",\n      ").concat(getColor(style.link.color), ",\n      ").concat(style.link.underline.on, ",\n      ").concat(getColor(style.link.underline.color), "\n      );");
        }
    }
    if (style.animation && style.animation.animationOption && style.animation.animationOption !== commonTypes_1.AnimationOption.None) {
        snippet += ".apply-animation(~\"".concat(blockId, "\",\n    ").concat(style.animation.animationOption, ",\n    ").concat(style.animation.animationType, ",\n    ").concat("come-".concat(style.animation.direction), ",\n    ").concat(style.animation.speed, "\n    );");
    }
    else {
        snippet += ".apply-no-animation(~\"".concat(blockId, "\");");
    }
    snippet += ".apply-fold-block(~\"".concat(blockId, "\", ").concat(defaultColor, ");");
    return snippet;
}
exports.getMiscSnippets = getMiscSnippets;
function getAcceptBlockStyleSnippet(block, getColor) {
    var style = block.style;
    if (!style || !(block.type === commonTypes_1.BlockType.Accept && (0, BlockStyleTypes_1.isAcceptStyle)(style))) {
        return "";
    }
    var blockId = getBlockId(block);
    var buttonAlign = style.acceptButton.alignment === "left" ? "left" : "center";
    var backgroundColor = getBackgroundColor(block, getColor);
    return ".apply-accept-block(~\"".concat(blockId, "\",\n    ").concat(getColor(style.acceptButton.color) || "#00A99D", ",\n    ").concat(buttonAlign, ",\n    ").concat(backgroundColor, "\n    );");
}
exports.getAcceptBlockStyleSnippet = getAcceptBlockStyleSnippet;
function getSourceBlockStyleSnippet(block, getColor) {
    var style = block.style;
    if (!style || !(block.type === commonTypes_1.BlockType.Source)) {
        return "";
    }
    return ".apply-source-block(~\"".concat(getBlockId(block), "\",\n    ").concat(getBackgroundColor(block, getColor), ",\n    ").concat((0, blockConfigUtil_1.getBlockWidthValue)(style.blockWidth), ",\n    ").concat((0, blockConfigUtil_1.getBlockSpacingValue)(style.blockSpacing), "\n    );");
}
exports.getSourceBlockStyleSnippet = getSourceBlockStyleSnippet;
function getCustomCssStyleForBlock(block) {
    return __awaiter(this, void 0, Promise, function () {
        var style, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    style = block.style;
                    _a = !style || !style.css;
                    if (_a) return [3, 2];
                    return [4, (0, LessValidator_1.isValidLESS)(style.css)];
                case 1:
                    _a = !(_b.sent());
                    _b.label = 2;
                case 2:
                    if (_a) {
                        return [2, ""];
                    }
                    return [2, ".project-block--id-".concat(getBlockId(block), " {").concat(style.css, "}")];
            }
        });
    });
}
function getCustomCssSnippetsForBlocks(blocks) {
    return __awaiter(this, void 0, Promise, function () {
        var allCustomCssStyles, blocks_1, blocks_1_1, block, _a, e_2_1;
        var e_2, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    allCustomCssStyles = "";
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 6, 7, 8]);
                    blocks_1 = __values(blocks), blocks_1_1 = blocks_1.next();
                    _c.label = 2;
                case 2:
                    if (!!blocks_1_1.done) return [3, 5];
                    block = blocks_1_1.value;
                    _a = allCustomCssStyles;
                    return [4, getCustomCssStyleForBlock(block)];
                case 3:
                    allCustomCssStyles = _a + _c.sent();
                    allCustomCssStyles += " ";
                    _c.label = 4;
                case 4:
                    blocks_1_1 = blocks_1.next();
                    return [3, 2];
                case 5: return [3, 8];
                case 6:
                    e_2_1 = _c.sent();
                    e_2 = { error: e_2_1 };
                    return [3, 8];
                case 7:
                    try {
                        if (blocks_1_1 && !blocks_1_1.done && (_b = blocks_1.return)) _b.call(blocks_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                    return [7];
                case 8: return [2, allCustomCssStyles];
            }
        });
    });
}
exports.getCustomCssSnippetsForBlocks = getCustomCssSnippetsForBlocks;
var GetColor = function (colors) {
    return function (colorObj) {
        if (!colorObj) {
            return "";
        }
        var themeIndex = colorObj.themeIndex, abs = colorObj.abs, opacity = colorObj.opacity;
        var themeColor = themeIndex != null ? colors[(themeIndex + colors.length) % colors.length] : null;
        if (themeIndex != null && themeColor) {
            if (opacity == null) {
                return themeColor;
            }
            else {
                return (0, colord_1.colord)(themeColor).alpha(opacity).toRgbString();
            }
        }
        else if (opacity != null) {
            return (0, colord_1.colord)(abs).alpha(opacity).toRgbString();
        }
        else {
            return sanitiseAbsColor(abs);
        }
    };
};
exports.GetColor = GetColor;
var sanitiseAbsColor = function (color) {
    var sanitisedColor = color;
    if ((0, ColorUtils_1.isValidHex)(color) && color.charAt(0) !== "#") {
        sanitisedColor = "#".concat(color);
    }
    return sanitisedColor;
};
var getSize = function (sizeObj) {
    return sizeObj.abs;
};
exports.getSize = getSize;
var getTintColorValue = function (tintBlendMode, backgroundColor, getColor) {
    if (tintBlendMode === "blend") {
        return "transparent";
    }
    else {
        return getColor(backgroundColor);
    }
};
exports.getTintColorValue = getTintColorValue;
var convertColorToColorObj = function (color) {
    return {
        abs: color.value,
        themeIndex: color === null || color === void 0 ? void 0 : color.themeIndex,
    };
};
exports.convertColorToColorObj = convertColorToColorObj;
var convertColorObjToColor = function (color) {
    var _a;
    return {
        value: color.abs,
        themeIndex: (_a = color === null || color === void 0 ? void 0 : color.themeIndex) !== null && _a !== void 0 ? _a : null,
    };
};
exports.convertColorObjToColor = convertColorObjToColor;
function sanitizeColor(color, options) {
    if (!color) {
        return null;
    }
    if (color.themeIndex !== null) {
        var foundColor = options.find(function (option) { return option.themeIndex === color.themeIndex; });
        if (foundColor) {
            return foundColor;
        }
    }
    if (!("value" in color)) {
        return null;
    }
    return {
        themeIndex: null,
        value: color.value,
    };
}
exports.sanitizeColor = sanitizeColor;
var isAssetBackground = function (backgroundStyle) {
    return backgroundStyle.type === "image" || backgroundStyle.type === "video";
};
exports.isAssetBackground = isAssetBackground;
