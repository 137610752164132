"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.idealTextBlockSchema = exports.embedBlockValidationSchema = exports.embedBlockStylesSchema = exports.videoBlockValidationSchema = exports.videoBlockStyleSchema = exports.splashBlockValidationSchema = exports.splashBlockStylesSchema = exports.textBlockValidationSchema = exports.textBlockStyleSchema = exports.sharedBlockStylesBasedOnText = exports.idealTextBlockStyleSchema = exports.genericTextSchema = exports.idealGenericTextSchema = exports.idealColorSchema = exports.objectIdSchema = void 0;
var zod_1 = require("zod");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var BlockStyleTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/BlockStyleTypes");
var idSchema = zod_1.z.string().regex(/^[0-9a-fA-F]{24}$/);
exports.objectIdSchema = zod_1.z.any().or(idSchema);
var dateSchema = zod_1.z.date().or(zod_1.z.string());
var baseBlockSchema = zod_1.z.strictObject({
    name: zod_1.z.string(),
    accountId: exports.objectIdSchema.optional(),
    _id: exports.objectIdSchema,
    cssBlockName: zod_1.z.string(),
    projectId: exports.objectIdSchema.optional(),
    createdBy: exports.objectIdSchema.optional().nullable(),
    createdAt: dateSchema,
    persistVersion: zod_1.z.number(),
    isAccepted: zod_1.z.boolean().optional(),
    __v: zod_1.z.number().optional(),
    lock: zod_1.z.any().optional(),
    updatedAt: dateSchema.optional(),
    account: zod_1.z.string().or(zod_1.z.object({})).optional(),
    sortOrder: zod_1.z.number().optional(),
    user: zod_1.z.string().or(zod_1.z.any()).optional(),
    blockPosition: zod_1.z.number().optional(),
    savedBlockName: zod_1.z.string().optional(),
    clonedFrom: zod_1.z.string().optional(),
    fromSavedBlock: zod_1.z.string().optional(),
    id: zod_1.z.string().optional(),
    lastUsedAt: dateSchema.optional(),
    lastEditedAt: dateSchema.optional(),
    parentFolder: exports.objectIdSchema.nullable().optional(),
});
var colorSchema = zod_1.z
    .strictObject({
    themeIndex: zod_1.z.number().nullable().optional(),
    abs: zod_1.z.string().optional(),
    opacity: zod_1.z.number().optional(),
})
    .or(zod_1.z.strictObject({
    themeIndex: zod_1.z.number().nullable().optional(),
    value: zod_1.z.string().optional(),
}))
    .or(zod_1.z.strictObject({
    themeIndex: zod_1.z.number().nullable().optional(),
    abs: zod_1.z.string().optional(),
    opacity: zod_1.z.number().optional(),
    value: zod_1.z.string().optional(),
}));
exports.idealColorSchema = zod_1.z.strictObject({
    themeIndex: zod_1.z.number().nullable().optional(),
    abs: zod_1.z.string(),
    opacity: zod_1.z.number().nullable().optional(),
});
var sizeSchema = zod_1.z.strictObject({
    abs: zod_1.z.number().or(zod_1.z.string()).or(zod_1.z.literal("")).nullable().optional(),
    themeRef: zod_1.z.string().nullable().optional(),
});
var idealSizeSchema = zod_1.z.strictObject({
    abs: zod_1.z.number().or(zod_1.z.string()),
    themeRef: zod_1.z.string().nullable().optional(),
});
var baseStyleSchema = zod_1.z.strictObject({
    __v: zod_1.z.number().optional(),
    hash: zod_1.z.string().optional(),
    preTcdcRef: zod_1.z.string().optional(),
    isFactoryStyle: zod_1.z.boolean().optional(),
    account: zod_1.z.string().optional(),
    _id: exports.objectIdSchema.optional(),
    name: zod_1.z.string().optional(),
    clonedFrom: exports.objectIdSchema.optional().nullable(),
});
exports.idealGenericTextSchema = zod_1.z.strictObject({
    color: exports.idealColorSchema,
    size: idealSizeSchema,
    alignment: zod_1.z.nativeEnum(commonTypes_1.AlignmentTypes),
    lineHeight: zod_1.z.number().optional(),
    letterSpacing: zod_1.z.number().optional(),
    letterCase: zod_1.z.number().optional(),
});
exports.genericTextSchema = zod_1.z
    .strictObject({
    color: colorSchema.nullable().optional(),
    size: sizeSchema.nullable().optional(),
    alignment: zod_1.z.string().optional(),
})
    .optional();
exports.idealTextBlockStyleSchema = zod_1.z.strictObject({
    isFactoryStyle: zod_1.z.boolean().optional(),
    preTcdcRef: zod_1.z.string().optional(),
    name: zod_1.z.string().optional(),
    blockType: zod_1.z.literal(commonTypes_1.BlockType.Text),
    legacyBlockType: zod_1.z.nativeEnum(commonTypes_1.BlockType).or(zod_1.z.literal("Text")).or(zod_1.z.literal("Source")).optional(),
    css: zod_1.z.string().optional(),
    alignment: zod_1.z.string().optional(),
    background: exports.idealColorSchema.merge(zod_1.z.strictObject({
        type: zod_1.z.enum(["image", "video", "color"]),
        tintBlendMode: zod_1.z.enum(["normal", "blend"]),
        tintColor: exports.idealColorSchema,
        url: zod_1.z.string().optional().nullable(),
        videoThumbnail: zod_1.z.string().optional().nullable(),
        card: zod_1.z.strictObject({
            enabled: zod_1.z.boolean(),
            color: exports.idealColorSchema,
        }),
    })),
    blockWidth: zod_1.z.enum(["small", "medium", "large"]),
    blockSpacing: zod_1.z.enum(["small", "medium", "large"]),
    blockHeight: zod_1.z.enum(["none", "small", "medium", "large"]),
    hash: zod_1.z.string(),
    animation: zod_1.z.strictObject({
        animationOption: zod_1.z.enum(["none", "section", "together"]),
        animationType: zod_1.z.enum(["fade", "slide"]),
        direction: zod_1.z.enum(["up", "down", "left", "right"]),
        speed: zod_1.z.enum(["slow", "medium", "fast"]),
    }),
    clonedFrom: exports.objectIdSchema.optional(),
    table: zod_1.z.strictObject({
        background: zod_1.z.strictObject({ color: exports.idealColorSchema, on: zod_1.z.boolean() }),
        header: zod_1.z.strictObject({ color: exports.idealColorSchema, on: zod_1.z.boolean() }),
        border: zod_1.z.strictObject({
            color: exports.idealColorSchema,
            borderType: zod_1.z.enum([commonTypes_1.TableBorderType.All, commonTypes_1.TableBorderType.Horizontal]),
        }),
    }),
    list: zod_1.z.strictObject({
        color: exports.idealColorSchema,
        marker: zod_1.z.enum(["circle", "square", "dash"]),
        markerColor: exports.idealColorSchema,
    }),
    link: zod_1.z.strictObject({
        color: exports.idealColorSchema,
        underline: zod_1.z.strictObject({
            on: zod_1.z.boolean(),
            color: exports.idealColorSchema,
        }),
    }),
    button: zod_1.z.strictObject({
        color: exports.idealColorSchema,
        roundness: zod_1.z.number(),
        size: zod_1.z.enum(["small", "medium", "large"]),
        textColor: zod_1.z.string(),
    }),
    roiCalculator: zod_1.z.strictObject({
        highlightColor: exports.idealColorSchema,
        textColor: zod_1.z.enum(["dark", "light"]),
        background: zod_1.z.strictObject({
            color: exports.idealColorSchema,
            imageBlur: zod_1.z.number(),
        }),
        tint: zod_1.z.strictObject({
            color: exports.idealColorSchema,
            opacity: zod_1.z.number(),
        }),
    }),
    accordion: zod_1.z.strictObject({
        headerBackground: zod_1.z.strictObject({
            on: zod_1.z.boolean(),
            color: exports.idealColorSchema,
        }),
        bodyBackground: zod_1.z.strictObject({
            on: zod_1.z.boolean(),
        }),
        buttonAlignment: zod_1.z.nativeEnum(BlockStyleTypes_1.AccordionWidgetButtonAlignment),
        buttonStyle: zod_1.z.nativeEnum(BlockStyleTypes_1.AccordionWidgetButtonStyle),
        buttonColor: exports.idealColorSchema,
        cardStyle: zod_1.z.nativeEnum(BlockStyleTypes_1.AccordionWidgetCardStyle),
        imageHeight: zod_1.z.nativeEnum(BlockStyleTypes_1.AccordionWidgetImageHeight),
        tint: zod_1.z.strictObject({
            color: exports.idealColorSchema,
            opacity: zod_1.z.number(),
        }),
        expandButtonText: zod_1.z.string(),
        closeButtonText: zod_1.z.string(),
    }),
    h1: exports.idealGenericTextSchema,
    h2: exports.idealGenericTextSchema,
    h3: exports.genericTextSchema,
    h4: exports.genericTextSchema,
    p: exports.idealGenericTextSchema,
    smallText: exports.genericTextSchema,
    captionText: exports.genericTextSchema,
    blockQuote: zod_1.z.strictObject({
        color: exports.idealColorSchema,
        size: idealSizeSchema,
        barColor: exports.idealColorSchema,
        displayQuoteIcon: zod_1.z.boolean(),
        alignment: zod_1.z.string().optional(),
    }),
    caption: zod_1.z
        .strictObject({ color: colorSchema.optional(), size: sizeSchema.optional(), alignment: zod_1.z.string().optional() })
        .optional(),
    _id: exports.objectIdSchema.optional(),
    isMigrated: zod_1.z.boolean().optional(),
    migratedAt: dateSchema.optional(),
    account: exports.objectIdSchema.optional(),
});
exports.sharedBlockStylesBasedOnText = baseStyleSchema.merge(zod_1.z.strictObject({
    blockType: zod_1.z.literal(commonTypes_1.BlockType.Text).or(zod_1.z.literal(commonTypes_1.BlockType.Accept)).optional(),
    css: zod_1.z.string().optional(),
    alignment: zod_1.z.string().optional(),
    acceptButton: zod_1.z
        .strictObject({ color: colorSchema.or(zod_1.z.strictObject({})), alignment: zod_1.z.string().optional() })
        .optional(),
    h1: exports.genericTextSchema,
    h2: exports.genericTextSchema,
    h3: exports.genericTextSchema,
    h4: exports.genericTextSchema,
    p: exports.genericTextSchema,
    smallText: exports.genericTextSchema,
    captionText: exports.genericTextSchema,
    blockQuote: zod_1.z
        .strictObject({
        color: colorSchema.optional(),
        size: sizeSchema.nullable().optional(),
        barColor: colorSchema.optional(),
        displayQuoteIcon: zod_1.z.boolean().optional(),
        alignment: zod_1.z.string().optional(),
    })
        .optional(),
    background: zod_1.z
        .strictObject({
        themeIndex: zod_1.z.number().nullable().optional(),
        abs: zod_1.z.string().optional(),
        opacity: zod_1.z.number().optional(),
        tintBlendMode: zod_1.z.enum(["normal", "blend"]).optional(),
        type: zod_1.z.enum(["image", "video", "color"]).optional(),
        tintColor: zod_1.z.union([exports.idealColorSchema, zod_1.z.strictObject({})]).optional(),
        url: zod_1.z.string().optional(),
        videoThumbnail: zod_1.z.string().optional().nullable(),
        card: zod_1.z
            .strictObject({
            enabled: zod_1.z.boolean().optional(),
            color: zod_1.z.union([zod_1.z.strictObject({}), exports.idealColorSchema]).optional(),
        })
            .optional(),
    })
        .optional()
        .nullable(),
    textSize: zod_1.z
        .strictObject({
        abs: zod_1.z.number(),
        ref: zod_1.z.string(),
    })
        .optional(),
    blockWidth: zod_1.z.enum(["small", "medium", "large"]).optional(),
    blockSpacing: zod_1.z.enum(["small", "medium", "large"]).optional(),
    animation: zod_1.z
        .strictObject({
        animationOption: zod_1.z.enum(["none", "section", "together"]),
        animationType: zod_1.z.enum(["fade", "slide"]),
        direction: zod_1.z.enum(["up", "down", "left", "right"]),
        speed: zod_1.z.enum(["slow", "medium", "fast"]),
    })
        .optional(),
    tableBackground: zod_1.z
        .strictObject({
        on: zod_1.z.boolean(),
        color: colorSchema,
    })
        .optional(),
    tableHeader: zod_1.z
        .strictObject({
        on: zod_1.z.boolean(),
        color: colorSchema,
    })
        .optional(),
    tableBorder: zod_1.z
        .strictObject({
        color: colorSchema,
        borderType: zod_1.z.string(),
    })
        .optional(),
    list: zod_1.z
        .strictObject({
        color: colorSchema,
        marker: zod_1.z.enum(["circle", "square", "dash"]),
        markerColor: colorSchema,
    })
        .optional(),
    link: zod_1.z
        .strictObject({
        color: colorSchema.or(zod_1.z.strictObject({})),
        underline: zod_1.z
            .strictObject({
            on: zod_1.z.boolean(),
            color: colorSchema,
        })
            .optional(),
        underlineColor: zod_1.z.strictObject({}).optional(),
    })
        .optional(),
    button: zod_1.z
        .strictObject({
        color: zod_1.z.any(),
        roundness: zod_1.z.number(),
        size: zod_1.z.enum(["small", "medium", "large"]),
        textColor: zod_1.z.string(),
    })
        .optional(),
    roiCalculator: zod_1.z
        .strictObject({
        highlightColor: zod_1.z.any(),
        textColor: zod_1.z.string(),
        background: zod_1.z.strictObject({
            color: zod_1.z.any(),
            imageBlur: zod_1.z.number(),
        }),
        tint: zod_1.z
            .strictObject({
            color: zod_1.z.any(),
            opacity: zod_1.z.number(),
        })
            .optional(),
        overlay: zod_1.z.any(),
    })
        .optional(),
    accordion: zod_1.z
        .strictObject({
        headerBackground: zod_1.z.strictObject({
            on: zod_1.z.boolean(),
            color: zod_1.z.any(),
        }),
        bodyBackground: zod_1.z.strictObject({
            on: zod_1.z.boolean(),
        }),
        buttonAlignment: zod_1.z.string().optional(),
        buttonStyle: zod_1.z.string().optional(),
        buttonColor: zod_1.z.any(),
        cardStyle: zod_1.z.string().optional(),
        imageHeight: zod_1.z.string().optional(),
        tint: zod_1.z
            .strictObject({
            color: zod_1.z.any(),
            opacity: zod_1.z.number(),
        })
            .optional(),
        expandButtonText: zod_1.z.string().optional(),
        closeButtonText: zod_1.z.string().optional(),
        size: zod_1.z.unknown().optional(),
        color: zod_1.z.unknown().optional(),
    })
        .optional(),
    caption: zod_1.z.strictObject({ color: colorSchema, size: sizeSchema, alignment: zod_1.z.string() }).optional(),
    headerSection: zod_1.z.strictObject({ color: colorSchema.or(zod_1.z.strictObject({})), on: zod_1.z.boolean().optional() }).optional(),
    buttonColor: colorSchema.or(zod_1.z.strictObject({})).optional(),
    uiHighlightColor: colorSchema.or(zod_1.z.strictObject({})).optional(),
    quoteBackgroundOn: zod_1.z.boolean().optional(),
    backgroundCard: zod_1.z
        .strictObject({
        on: zod_1.z.boolean().optional(),
        color: colorSchema.optional().or(zod_1.z.strictObject({})),
    })
        .optional(),
    table: zod_1.z
        .strictObject({
        background: zod_1.z.strictObject({
            on: zod_1.z.boolean().optional(),
            color: colorSchema,
        }),
        header: zod_1.z.strictObject({
            on: zod_1.z.boolean().optional(),
            color: colorSchema,
        }),
        border: zod_1.z.strictObject({
            color: colorSchema,
            borderType: zod_1.z.string().optional(),
        }),
    })
        .optional(),
    tintBlendMode: zod_1.z.enum(["normal", "blend"]).optional(),
    blockHeight: zod_1.z.enum(["none", "auto", "small", "medium", "large"]).optional(),
}));
exports.textBlockStyleSchema = exports.sharedBlockStylesBasedOnText
    .or(exports.sharedBlockStylesBasedOnText.merge(zod_1.z.object({
    blockType: zod_1.z.literal(commonTypes_1.BlockType.Quote).or(zod_1.z.literal(commonTypes_1.BlockType.Accept)),
})))
    .optional();
exports.textBlockValidationSchema = baseBlockSchema.merge(zod_1.z.strictObject({
    type: zod_1.z.literal(commonTypes_1.BlockType.Text),
    settings: zod_1.z.strictObject({
        navigation: zod_1.z.strictObject({ exclude: zod_1.z.boolean(), name: zod_1.z.string().optional() }).optional(),
        foldable: zod_1.z.any(),
        style: zod_1.z.string().optional(),
        isFolded: zod_1.z.boolean().optional(),
        foldableLabel: zod_1.z.any(),
    }),
    style: zod_1.z.union([exports.textBlockStyleSchema, exports.idealTextBlockStyleSchema]),
    contentAreas: zod_1.z.strictObject({ mainContent: zod_1.z.any(), content: zod_1.z.string().optional() }),
    architecture: zod_1.z.string().optional(),
}));
exports.splashBlockStylesSchema = baseStyleSchema
    .merge(zod_1.z.strictObject({
    blockType: zod_1.z.literal(commonTypes_1.BlockType.Splash).or(zod_1.z.literal("Text")).optional(),
    css: zod_1.z.string().optional(),
    alignment: zod_1.z.string().optional(),
    h1: zod_1.z
        .strictObject({
        color: colorSchema.nullable().optional(),
        size: sizeSchema.nullable().optional(),
        alignment: zod_1.z.nativeEnum(commonTypes_1.AlignmentTypes).optional(),
    })
        .optional(),
    h2: zod_1.z
        .strictObject({
        color: colorSchema.nullable().optional(),
        size: sizeSchema.nullable().optional(),
        alignment: zod_1.z.nativeEnum(commonTypes_1.AlignmentTypes).optional(),
    })
        .optional(),
    p: zod_1.z
        .strictObject({
        color: colorSchema.nullable().optional(),
        size: sizeSchema.nullable().optional(),
        alignment: zod_1.z.nativeEnum(commonTypes_1.AlignmentTypes).optional(),
    })
        .optional(),
    h3: exports.genericTextSchema,
    h4: exports.genericTextSchema,
    smallText: exports.genericTextSchema,
    captionText: exports.genericTextSchema,
    blockQuote: zod_1.z
        .strictObject({
        color: colorSchema.optional(),
        size: sizeSchema.optional(),
        barColor: colorSchema.optional(),
        displayQuoteIcon: zod_1.z.boolean().optional(),
        alignment: zod_1.z.string().optional(),
    })
        .optional(),
    background: colorSchema.or(zod_1.z.strictObject({})).nullable().optional(),
    tintBlendMode: zod_1.z.nativeEnum(commonTypes_1.TintBlendModeTypes).optional(),
    backgroundCard: zod_1.z
        .strictObject({
        on: zod_1.z.boolean().optional(),
        color: colorSchema.optional(),
    })
        .optional(),
    blockWidth: zod_1.z.nativeEnum(commonTypes_1.OptionLabelTypes).optional(),
    blockSpacing: zod_1.z.enum(["small", "medium", "large"]).optional(),
    hash: zod_1.z.string().optional(),
    animation: zod_1.z
        .strictObject({
        animationOption: zod_1.z.nativeEnum(commonTypes_1.AnimationOption).optional(),
        animationType: zod_1.z.nativeEnum(commonTypes_1.AnimationType).optional(),
        direction: zod_1.z.nativeEnum(commonTypes_1.AnimationDirection).optional(),
        speed: zod_1.z.nativeEnum(commonTypes_1.AnimationSpeed).optional(),
    })
        .optional(),
    tableBackground: zod_1.z
        .strictObject({
        on: zod_1.z.boolean().optional(),
        color: colorSchema.optional(),
    })
        .optional(),
    tableHeader: zod_1.z
        .strictObject({
        on: zod_1.z.boolean().optional(),
        color: colorSchema.optional(),
    })
        .optional(),
    tableBorder: zod_1.z
        .strictObject({
        color: colorSchema.optional(),
        borderType: zod_1.z.string().optional(),
    })
        .optional(),
    list: zod_1.z
        .strictObject({
        color: colorSchema.optional(),
        marker: zod_1.z.nativeEnum(commonTypes_1.ListMarkerType).optional(),
        markerColor: colorSchema.optional(),
    })
        .optional(),
    link: zod_1.z
        .strictObject({
        color: colorSchema.or(zod_1.z.strictObject({})).optional(),
        underline: zod_1.z
            .strictObject({
            on: zod_1.z.boolean(),
            color: colorSchema,
        })
            .optional(),
        underlineColor: zod_1.z.strictObject({}).optional(),
    })
        .optional(),
    button: zod_1.z
        .strictObject({
        color: zod_1.z.any(),
        roundness: zod_1.z.number(),
        size: zod_1.z.nativeEnum(BlockStyleTypes_1.ButtonWidgetSize),
        textColor: zod_1.z.string(),
    })
        .optional(),
    roiCalculator: zod_1.z
        .strictObject({
        highlightColor: zod_1.z.any(),
        textColor: zod_1.z.string(),
        background: zod_1.z.strictObject({
            color: zod_1.z.any(),
            imageBlur: zod_1.z.number(),
        }),
        tint: zod_1.z
            .strictObject({
            color: zod_1.z.any(),
            opacity: zod_1.z.number(),
        })
            .optional(),
        overlay: zod_1.z.any(),
    })
        .optional(),
    accordion: zod_1.z
        .strictObject({
        headerBackground: zod_1.z.strictObject({
            on: zod_1.z.boolean(),
            color: zod_1.z.any(),
        }),
        bodyBackground: zod_1.z.strictObject({
            on: zod_1.z.boolean(),
        }),
        buttonAlignment: zod_1.z.nativeEnum(BlockStyleTypes_1.AccordionWidgetButtonAlignment).optional(),
        buttonStyle: zod_1.z.nativeEnum(BlockStyleTypes_1.AccordionWidgetButtonStyle).optional(),
        buttonColor: colorSchema.optional(),
        cardStyle: zod_1.z.string().optional(),
        imageHeight: zod_1.z.string().optional(),
        tint: zod_1.z
            .strictObject({
            color: zod_1.z.any(),
            opacity: zod_1.z.number(),
        })
            .optional(),
        expandButtonText: zod_1.z.string().optional(),
        closeButtonText: zod_1.z.string().optional(),
        size: zod_1.z.unknown().optional(),
        color: zod_1.z.unknown().optional(),
    })
        .optional(),
    caption: zod_1.z.strictObject({ color: colorSchema, size: sizeSchema, alignment: zod_1.z.string() }).optional(),
    acceptButton: zod_1.z.strictObject({ color: colorSchema.or(zod_1.z.strictObject({})) }).optional(),
    headerSection: zod_1.z.strictObject({ color: colorSchema.or(zod_1.z.strictObject({})) }).optional(),
    buttonColor: colorSchema.or(zod_1.z.strictObject({})).optional(),
    uiHighlightColor: zod_1.z.strictObject({}).optional(),
    textSize: zod_1.z.strictObject({ ref: zod_1.z.string(), abs: zod_1.z.number() }).optional(),
    table: zod_1.z
        .strictObject({
        background: zod_1.z.strictObject({
            on: zod_1.z.boolean(),
            color: colorSchema,
        }),
        header: zod_1.z.strictObject({
            on: zod_1.z.boolean(),
            color: colorSchema,
        }),
        border: zod_1.z.strictObject({
            color: colorSchema,
            borderType: zod_1.z.string(),
        }),
    })
        .optional(),
}))
    .optional();
exports.splashBlockValidationSchema = baseBlockSchema.merge(zod_1.z.strictObject({
    type: zod_1.z.literal(commonTypes_1.BlockType.Splash),
    settings: zod_1.z.strictObject({
        navigation: zod_1.z.strictObject({ exclude: zod_1.z.boolean(), name: zod_1.z.string().optional() }).optional(),
        foldable: zod_1.z.any(),
        style: zod_1.z.string().optional(),
        size: zod_1.z.enum(["fullpage", "half", "third", "full"]).optional(),
        withContent: zod_1.z.boolean().optional(),
        withContentBackground: zod_1.z.boolean().optional(),
        tint: zod_1.z.strictObject({ on: zod_1.z.boolean(), color: zod_1.z.string() }).or(zod_1.z.strictObject({})).optional(),
    }),
    style: zod_1.z.union([exports.splashBlockStylesSchema, exports.idealTextBlockStyleSchema]),
    contentAreas: zod_1.z.strictObject({ main: zod_1.z.any(), widgets: zod_1.z.any() }),
    resourceType: zod_1.z.enum(["image", "video", "embed"]).optional(),
    resourceUrl: zod_1.z.string().nullable().optional(),
    imageUrl: zod_1.z.string().optional(),
    sections: zod_1.z.tuple([]).optional(),
    locked: zod_1.z.boolean().optional(),
    moduleName: zod_1.z.string().optional(),
    architecture: zod_1.z.string().optional(),
    __t: zod_1.z.string().optional(),
}));
exports.videoBlockStyleSchema = exports.sharedBlockStylesBasedOnText
    .or(exports.sharedBlockStylesBasedOnText.merge(zod_1.z.object({
    blockType: zod_1.z.literal(commonTypes_1.BlockType.Quote).or(zod_1.z.literal(commonTypes_1.BlockType.Accept)),
})))
    .optional();
exports.videoBlockValidationSchema = baseBlockSchema.merge(zod_1.z.strictObject({
    type: zod_1.z.literal(commonTypes_1.BlockType.Video),
    settings: zod_1.z.strictObject({
        navigation: zod_1.z.strictObject({ exclude: zod_1.z.boolean(), name: zod_1.z.string().optional() }).optional(),
        foldable: zod_1.z.any(),
        style: zod_1.z.string().optional(),
        loop: zod_1.z.boolean().optional(),
        autoplay: zod_1.z.boolean().optional(),
        size: zod_1.z.enum(["small", "medium", "standard"]).optional(),
    }),
    style: zod_1.z.union([exports.videoBlockStyleSchema, exports.idealTextBlockStyleSchema]),
    contentAreas: zod_1.z.strictObject({
        header: zod_1.z
            .strictObject({
            tokens: zod_1.z.any().array().optional(),
            content: zod_1.z.string().optional(),
            widgets: zod_1.z.any().optional(),
        })
            .optional(),
    }),
    url: zod_1.z.string(),
    embedUrl: zod_1.z.string().nullable(),
    sections: zod_1.z.tuple([]).optional(),
    moduleName: zod_1.z.string().optional(),
    architecture: zod_1.z.string().optional(),
    __t: zod_1.z.string().optional(),
}));
exports.embedBlockStylesSchema = exports.sharedBlockStylesBasedOnText
    .or(exports.sharedBlockStylesBasedOnText.merge(zod_1.z.object({
    blockType: zod_1.z.literal(commonTypes_1.BlockType.Source).or(zod_1.z.literal(commonTypes_1.BlockType.Accept)).or(zod_1.z.literal(commonTypes_1.BlockType.Quote)),
})))
    .or(baseStyleSchema.merge(zod_1.z.strictObject({
    blockType: zod_1.z.literal("Source"),
    css: zod_1.z.string(),
    background: colorSchema,
    blockWidth: zod_1.z.enum(["small", "medium", "large"]),
    blockSpacing: zod_1.z.enum(["small", "medium", "large"]),
    animation: zod_1.z
        .strictObject({
        animationOption: zod_1.z.enum(["none", "section", "together"]),
        animationType: zod_1.z.enum(["fade", "slide"]),
        direction: zod_1.z.enum(["up", "down", "left", "right"]),
        speed: zod_1.z.enum(["slow", "medium", "fast"]),
    })
        .optional(),
})))
    .optional();
exports.embedBlockValidationSchema = baseBlockSchema.merge(zod_1.z.strictObject({
    type: zod_1.z.literal(commonTypes_1.BlockType.Source),
    settings: zod_1.z.strictObject({
        navigation: zod_1.z.strictObject({ exclude: zod_1.z.boolean(), name: zod_1.z.string().optional() }).optional(),
        foldable: zod_1.z.any(),
        style: zod_1.z.string().optional(),
        size: zod_1.z.literal("three-quarters").optional(),
    }),
    style: zod_1.z.union([exports.embedBlockStylesSchema, exports.idealTextBlockStyleSchema]),
    url: zod_1.z.string().optional(),
    sourceType: zod_1.z.string().optional(),
    contentAreas: zod_1.z.null().optional(),
    iframeUrl: zod_1.z.string().optional(),
    sections: zod_1.z.tuple([]).optional(),
}));
exports.idealTextBlockSchema = zod_1.z.strictObject({
    _id: exports.objectIdSchema,
    __v: zod_1.z.number().optional(),
    name: zod_1.z.string(),
    cssBlockName: zod_1.z.string(),
    projectId: exports.objectIdSchema,
    account: exports.objectIdSchema,
    createdBy: exports.objectIdSchema,
    createdAt: dateSchema,
    lock: zod_1.z.any().optional(),
    persistVersion: zod_1.z.number(),
    lastEditedAt: dateSchema,
    isAccepted: zod_1.z.boolean().optional(),
    displayType: zod_1.z.nativeEnum(commonTypes_1.BlockType),
    type: zod_1.z.literal(commonTypes_1.BlockType.Text),
    isMigrated: zod_1.z.boolean(),
    migratedAt: dateSchema.optional(),
    style: exports.idealTextBlockStyleSchema,
    settings: zod_1.z.strictObject({
        navigation: zod_1.z.strictObject({ exclude: zod_1.z.boolean(), name: zod_1.z.string().optional() }).optional(),
        foldable: zod_1.z.strictObject({
            isFolded: zod_1.z.boolean(),
            isFoldable: zod_1.z.boolean(),
            label: zod_1.z.string(),
        }),
        style: zod_1.z.string().optional(),
    }),
    contentAreas: zod_1.z.strictObject({
        mainContent: zod_1.z.strictObject({
            content: zod_1.z.string(),
            tokens: zod_1.z.array(zod_1.z.any()).optional(),
            widgets: zod_1.z.any().optional(),
        }),
        widgets: zod_1.z.any().optional().nullable(),
    }),
    clonedFrom: zod_1.z.string().optional(),
    fromSavedBlock: zod_1.z.string().optional(),
    savedBlockName: zod_1.z.string().optional(),
    id: zod_1.z.string().optional(),
    lastUsedAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
    parentFolder: zod_1.z.string().or(zod_1.z.object({})).nullable().optional(),
});
