"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.endPerfMeasurement = exports.startPerfMeasurement = exports.Measurement = void 0;
var faro_web_sdk_1 = require("@grafana/faro-web-sdk");
var Measurement;
(function (Measurement) {
    Measurement["LOAD_PROJECT_EDITOR"] = "loadProjectEditor";
})(Measurement || (exports.Measurement = Measurement = {}));
function startPerfMeasurement(measurement) {
    performance.mark("".concat(measurement, "-start"));
}
exports.startPerfMeasurement = startPerfMeasurement;
function endPerfMeasurement(measurement) {
    var _a;
    performance.mark("".concat(measurement, "-end"));
    var duration = performance.measure(measurement, "".concat(measurement, "-start"), "".concat(measurement, "-end")).duration;
    (_a = faro_web_sdk_1.faro.api) === null || _a === void 0 ? void 0 : _a.pushMeasurement({
        type: "frontend_performance_measurement",
        values: {
            duration: duration,
        },
    }, {
        context: {
            measurement_name: measurement,
        },
    });
}
exports.endPerfMeasurement = endPerfMeasurement;
