"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createsmallToken = exports.SmallTextConfig = void 0;
var SmallText_css_1 = require("@CommonFrontend/ContentTokens/SharedWidgetComponents/Text/SmallText.css");
var GenericText_1 = require("./GenericText");
exports.SmallTextConfig = (0, GenericText_1.makeGenericTextConfig)("smallText", "p", { vars: SmallText_css_1.vars, widget: SmallText_css_1.smallTextWidget, spacingShim: SmallText_css_1.smallTextSpacingShim }, { textColor: "DERIVED_FROM_PARAGRAPH_HACK", fontSize: 16, lineHeight: SmallText_css_1.defaultLineHeight }, "smallText");
function createsmallToken() {
    return {
        type: "block",
        subType: "smallText",
    };
}
exports.createsmallToken = createsmallToken;
;
