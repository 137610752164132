"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.widgetStyle = void 0;
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var lodash_1 = require("lodash");
var fontSizeMapping_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/fontSizeMapping");
var DefaultColors_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/DefaultColors");
var colord_1 = require("colord");
var widgetStyle = function (_a) {
    var e_1, _b;
    var _c, _d, _e;
    var blockStyle = _a.blockStyle, colorProvider = _a.colorProvider, overrides = _a.overrides, styleableConfig = _a.styleableConfig, paragraphBlockStyle = _a.paragraphBlockStyle, heading2BlockStyle = _a.heading2BlockStyle;
    if (!styleableConfig) {
        return {};
    }
    var baseStyle = (0, lodash_1.merge)({}, blockStyle, overrides);
    var finalStyle = {};
    try {
        for (var _f = __values(styleableConfig.properties), _g = _f.next(); !_g.done; _g = _f.next()) {
            var property = _g.value;
            if (property.type === "color") {
                var defaultValue = property.defaultValue;
                if (defaultValue === "DERIVED_FROM_PARAGRAPH_HACK") {
                    defaultValue = !!(paragraphBlockStyle === null || paragraphBlockStyle === void 0 ? void 0 : paragraphBlockStyle.color)
                        ? (0, GetBlockStyle_1.convertColorObjToColor)(paragraphBlockStyle.color)
                        : DefaultColors_1.DEFAULT_MIDNIGHT;
                }
                else if (defaultValue === "DERIVED_FROM_HEADING_HACK") {
                    defaultValue = !!(heading2BlockStyle === null || heading2BlockStyle === void 0 ? void 0 : heading2BlockStyle.color)
                        ? (0, GetBlockStyle_1.convertColorObjToColor)(heading2BlockStyle.color)
                        : DefaultColors_1.DEFAULT_MIDNIGHT;
                }
                var styleColor = ((0, lodash_1.get)(baseStyle, property.path) || defaultValue);
                var color = "abs" in styleColor ? (0, GetBlockStyle_1.convertColorObjToColor)(styleColor) : styleColor;
                var sanitisedColor = (0, lodash_1.cloneDeep)(colorProvider.decorateColor(color !== null && color !== void 0 ? color : defaultValue));
                maybeApplyOpacityChannel(styleColor, sanitisedColor, property);
                var remarshaledColor = __assign(__assign({}, (0, GetBlockStyle_1.convertColorToColorObj)(sanitisedColor)), (!!property.opacity
                    ? {
                        opacity: (_c = styleColor.opacity) !== null && _c !== void 0 ? _c : property.opacity.defaultValue,
                    }
                    : {}));
                (0, lodash_1.set)(finalStyle, property.path, remarshaledColor);
            }
            else if (property.type === "fontSize") {
                var paragraphFontSize = (_d = paragraphBlockStyle === null || paragraphBlockStyle === void 0 ? void 0 : paragraphBlockStyle.size) !== null && _d !== void 0 ? _d : { abs: 20, themeRef: null };
                var size = ((0, lodash_1.get)(baseStyle, property.path) ||
                    (property.useParagraphBlockFontSize ? paragraphFontSize : property.defaultValue));
                (0, lodash_1.set)(finalStyle, property.path, (0, fontSizeMapping_1.decorateFontSize)(styleableConfig.styleKey, size));
            }
            else {
                (0, lodash_1.set)(finalStyle, property.path, (_e = (0, lodash_1.get)(baseStyle, property.path)) !== null && _e !== void 0 ? _e : property.defaultValue);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return finalStyle;
};
exports.widgetStyle = widgetStyle;
var maybeApplyOpacityChannel = function (styleColor, sanitisedColor, property) {
    var _a;
    if (property.opacity) {
        var opacityToApply = "opacity" in styleColor ? (_a = styleColor.opacity) !== null && _a !== void 0 ? _a : property.opacity.defaultValue : property.opacity.defaultValue;
        sanitisedColor.value = (0, colord_1.colord)(sanitisedColor.value).alpha(opacityToApply).toRgbString();
    }
};
