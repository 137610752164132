"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCaptionTextToken = exports.CaptionTextConfig = void 0;
var CaptionText_css_1 = require("@CommonFrontend/ContentTokens/SharedWidgetComponents/Text/CaptionText.css");
var GenericText_1 = require("./GenericText");
exports.CaptionTextConfig = (0, GenericText_1.makeGenericTextConfig)("captionText", "p", { vars: CaptionText_css_1.vars, widget: CaptionText_css_1.captionTextWidget, spacingShim: CaptionText_css_1.captionTextSpacingShim }, { textColor: "DERIVED_FROM_PARAGRAPH_HACK", fontSize: 14, lineHeight: CaptionText_css_1.defaultLineHeight }, "captionText");
function createCaptionTextToken() {
    return {
        type: "block",
        subType: "captionText",
    };
}
exports.createCaptionTextToken = createCaptionTextToken;
;
