"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHeading3Token = exports.Heading3Config = void 0;
var Heading3_css_1 = require("ContentTokens/SharedWidgetComponents/Text/Heading3.css");
var GenericText_1 = require("./GenericText");
exports.Heading3Config = (0, GenericText_1.makeGenericTextConfig)("heading3", "h3", { vars: Heading3_css_1.vars, widget: Heading3_css_1.h3Widget, spacingShim: Heading3_css_1.h3SpacingShim }, { textColor: "DERIVED_FROM_HEADING_HACK", fontSize: 32, lineHeight: Heading3_css_1.defaultLineHeight });
var createHeading3Token = function () { return ({
    type: "block",
    subType: "heading3",
}); };
exports.createHeading3Token = createHeading3Token;
