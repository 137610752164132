"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonWidgetRegistryConfigs = void 0;
var Chevron_1 = require("./CommonWidgets/Chevron");
var Box_1 = require("./CommonWidgets/Box");
var HorizontalRule_1 = require("./CommonWidgets/HorizontalRule");
var QuoteSummary_1 = require("./CommonWidgets/QuoteSummary");
var Heading1_1 = require("./CommonWidgets/Heading1");
var Heading2_1 = require("./CommonWidgets/Heading2");
var Heading3_1 = require("./CommonWidgets/Heading3");
var Heading4_1 = require("./CommonWidgets/Heading4");
var Paragraph_1 = require("./CommonWidgets/Paragraph");
var SmallText_1 = require("./CommonWidgets/SmallText");
var CaptionText_1 = require("./CommonWidgets/CaptionText");
var ListItem_1 = require("./CommonWidgets/ListItem");
var BlockQuote_1 = require("./CommonWidgets/BlockQuote");
var Video_1 = require("./CommonWidgets/Video");
var Button_1 = require("./CommonWidgets/Button");
var IFrame_1 = require("./CommonWidgets/IFrame");
var ROICalculator_1 = require("./CommonWidgets/ROICalculator");
var GoogleMaps_1 = require("./CommonWidgets/GoogleMaps");
var Image_1 = require("./CommonWidgets/Image");
var Table_1 = require("./CommonWidgets/Table");
var Accordion_1 = require("./CommonWidgets/Accordion");
var SideBySide_1 = require("./CommonWidgets/SideBySide");
var ContentLoop_1 = require("./CommonWidgets/ContentLoop");
var Layout_1 = require("./CommonWidgets/Layout");
var Variable_1 = require("./CommonWidgets/Variable");
exports.CommonWidgetRegistryConfigs = [
    Chevron_1.ChevronConfig,
    Box_1.BoxConfig,
    HorizontalRule_1.HorizontalRuleConfig,
    QuoteSummary_1.QuoteSummaryConfig,
    Heading1_1.Heading1Config,
    Heading2_1.Heading2Config,
    Heading3_1.Heading3Config,
    Heading4_1.Heading4Config,
    Paragraph_1.ParagraphConfig,
    SmallText_1.SmallTextConfig,
    CaptionText_1.CaptionTextConfig,
    ListItem_1.ListItemConfig,
    BlockQuote_1.BlockQuoteConfig,
    Video_1.VideoConfig,
    Button_1.ButtonConfig,
    IFrame_1.IFrameConfig,
    ROICalculator_1.ROICalculatorConfig,
    GoogleMaps_1.GoogleMapsConfig,
    Image_1.ImageConfig,
    Table_1.TableConfig,
    Accordion_1.AccordionConfig,
    SideBySide_1.SideBySideConfig,
    ContentLoop_1.ContentLoopConfig,
    Layout_1.LayoutConfig,
    Variable_1.VariableConfig,
];
