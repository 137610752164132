"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractAssignableStyleKeys = exports.isTextStyle = exports.isQuoteStyle = exports.isAcceptStyle = exports.isSplashStyle = exports.isSourceStyle = exports.isStyle = exports.AccordionWidgetButtonAlignment = exports.AccordionWidgetButtonStyle = exports.AccordionWidgetImageHeight = exports.AccordionWidgetCardStyle = exports.ButtonWidgetSize = void 0;
var assertUnreachable_1 = require("@CommonFrontendBackend/Utils/assertUnreachable");
var commonTypes_1 = require("./Config/BlockConfig/commonTypes");
var ButtonWidgetSize;
(function (ButtonWidgetSize) {
    ButtonWidgetSize["Small"] = "small";
    ButtonWidgetSize["Medium"] = "medium";
    ButtonWidgetSize["Large"] = "large";
})(ButtonWidgetSize || (exports.ButtonWidgetSize = ButtonWidgetSize = {}));
var AccordionWidgetCardStyle;
(function (AccordionWidgetCardStyle) {
    AccordionWidgetCardStyle["Simple"] = "simple";
    AccordionWidgetCardStyle["Visual"] = "visual";
})(AccordionWidgetCardStyle || (exports.AccordionWidgetCardStyle = AccordionWidgetCardStyle = {}));
var AccordionWidgetImageHeight;
(function (AccordionWidgetImageHeight) {
    AccordionWidgetImageHeight["Small"] = "small";
    AccordionWidgetImageHeight["Medium"] = "medium";
    AccordionWidgetImageHeight["Large"] = "large";
})(AccordionWidgetImageHeight || (exports.AccordionWidgetImageHeight = AccordionWidgetImageHeight = {}));
var AccordionWidgetButtonStyle;
(function (AccordionWidgetButtonStyle) {
    AccordionWidgetButtonStyle["Arrow"] = "arrow";
    AccordionWidgetButtonStyle["Expand"] = "expand";
    AccordionWidgetButtonStyle["Label"] = "label";
})(AccordionWidgetButtonStyle || (exports.AccordionWidgetButtonStyle = AccordionWidgetButtonStyle = {}));
var AccordionWidgetButtonAlignment;
(function (AccordionWidgetButtonAlignment) {
    AccordionWidgetButtonAlignment["Left"] = "left";
    AccordionWidgetButtonAlignment["Right"] = "right";
})(AccordionWidgetButtonAlignment || (exports.AccordionWidgetButtonAlignment = AccordionWidgetButtonAlignment = {}));
var isStyle = function (style) {
    return !!style.blockType;
};
exports.isStyle = isStyle;
var isSourceStyle = function (style) {
    return style.blockType === commonTypes_1.BlockType.Source;
};
exports.isSourceStyle = isSourceStyle;
var isSplashStyle = function (style) {
    return style.blockType === commonTypes_1.BlockType.Splash;
};
exports.isSplashStyle = isSplashStyle;
var isAcceptStyle = function (style) {
    return style.blockType === commonTypes_1.BlockType.Accept;
};
exports.isAcceptStyle = isAcceptStyle;
var isQuoteStyle = function (style) {
    return style.blockType === commonTypes_1.BlockType.Quote;
};
exports.isQuoteStyle = isQuoteStyle;
var isTextStyle = function (style) {
    return style.blockType === commonTypes_1.BlockType.Text;
};
exports.isTextStyle = isTextStyle;
function extractAssignableStyleKeys(style) {
    var minimumKeys = ["background", "animation", "blockWidth", "blockSpacing", "css"];
    var baseKeys = __spreadArray(__spreadArray([], __read(minimumKeys), false), [
        "h1",
        "h2",
        "h3",
        "h4",
        "p",
        "smallText",
        "captionText",
        "blockQuote",
        "list",
        "link",
        "button",
        "accordion",
        "roicalculator",
        "table",
        "textSize",
        "alignment",
    ], false);
    var exists = function (k) { return style.hasOwnProperty(k); };
    if ((0, exports.isSourceStyle)(style)) {
        return minimumKeys.filter(exists);
    }
    else if ((0, exports.isSplashStyle)(style)) {
        return __spreadArray(["backgroundCard", "tintBlendMode"], __read(baseKeys), false).filter(exists);
    }
    else if ((0, exports.isAcceptStyle)(style)) {
        return __spreadArray(["acceptButton"], __read(baseKeys), false).filter(exists);
    }
    else if ((0, exports.isQuoteStyle)(style)) {
        return __spreadArray(["uiHighlightColor", "headerSection", "quoteBackgroundOn"], __read(baseKeys), false).filter(exists);
    }
    else if ((0, exports.isTextStyle)(style)) {
        return __spreadArray(["blockHeight", "name"], __read(baseKeys), false).filter(exists);
    }
    else if (style.blockType === commonTypes_1.BlockType.Agreement) {
        return baseKeys.filter(exists);
    }
    (0, assertUnreachable_1.assertUnreachable)(style);
    return [];
}
exports.extractAssignableStyleKeys = extractAssignableStyleKeys;
